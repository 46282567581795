import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/tournament',
        name: 'tournament-list',
        components: {
          default: () => import('../pages/TournamentList/TournamentList.vue'),
          topBar: () => import('../navigation/TournamentListTopBar.vue'),
        },
      },
      {
        path: 'tournament/:id',
        name: 'tournament-landing',
        redirect: (to) => {
          return {
            name: 'tournament-standings',
            params: { id: to.params.id },
          };
        },
      },
      {
        path: 'tournament/:id/standings',
        name: 'tournament-standings',
        components: {
          default: () => import('../pages/TournamentView/Standings.vue'),
          topBar: () => import('../navigation/TournamentTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
        },
      },
      {
        path: 'tournament/:id/prizes',
        name: 'tournament-prizes',
        components: {
          default: () => import('../pages/TournamentView/Prizes.vue'),
          topBar: () => import('../navigation/TournamentTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
        },
      },
      {
        path: '',
        name: 'league-list',
        components: {
          default: () => import('../pages/LeagueList/LeagueList.vue'),
          topBar: () => import('../navigation/LeagueListTopBar.vue'),
        },
      },
      {
        path: 'league/:id',
        name: 'league-landing',
        redirect: (to) => {
          return {
            name: 'league-weekly-standings',
            params: { id: to.params.id, week: to.params.week },
          };
        },
      },
      {
        path: 'league/:id/standings',
        name: 'league-standings',
        components: {
          default: () => import('../pages/LeagueView/Standings.vue'),
          topBar: () => import('../navigation/FlexLeagueTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
        },
      },
      {
        path: 'league/:id/stats',
        name: 'league-statistics',
        components: {
          default: () => import('../pages/LeagueView/FlexLeagueStats.vue'),
          topBar: () => import('../navigation/FlexLeagueTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
        },
      },
      {
        path: 'league/:id/week/:week?',
        name: 'league-weekly-standings',
        components: {
          default: () => import('../pages/LeagueView/WeeklyStandings.vue'),
          topBar: () => import('../navigation/FlexLeagueTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
            week: Number.parseInt(params.week as string, 10) || 1,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
            week: Number.parseInt(params.week as string, 10) || 1,
          }),
        },
      },
      {
        path: 'league/:id/schedule/:week?',
        name: 'league-schedule',
        components: {
          default: () => import('../pages/LeagueView/TeamSchedule.vue'),
          topBar: () => import('../navigation/FlexLeagueTopBar.vue'),
        },
        props: {
          default: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
            week: Number.parseInt(params.week as string, 10) || 1,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
            week: Number.parseInt(params.week as string, 10) || 1,
          }),
        },
      },
      {
        path: 'player/:id',
        name: 'player-view',
        components: {
          default: () => import('../pages/LeaguePlayer/LeaguePlayerView.vue'),
          topBar: () => import('../navigation/PlayerTopBar.vue'),
        },
        props: {
          default: (route) => ({
            id: Number.parseInt(route.params.id as string, 10) || 0,
          }),
          topBar: ({ params }) => ({
            id: Number.parseInt(params.id as string, 10) || 0,
          }),
        },
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
